const apiServer = 'https://chicago.app.1life.com';

export const environment = {
  environmentName: 'alpha',
  enableProdMode: true,
  devMode: true,
  localLogs: true,
  topLevelDomain: '.app.1life.com',
  apiServer: apiServer,
  onelifeServer: apiServer,
  adminServer: 'https://chicago-admin.1life.com',
  chartServer: 'https://chicago-ui.app.1life.com',
  storybookRoute: '/design/index.html',
  firebase: {
    useEmulator: false,
    options: {
      apiKey: 'AIzaSyB2B4iyZdrow07wgvH1jGAO3JDZ0AmWIjw',
      authDomain: 'one-medical-development.firebaseapp.com',
      databaseURL: 'https://one-medical-development.firebaseio.com',
      projectId: 'one-medical-development',
      storageBucket: 'one-medical-development.appspot.com',
      messagingSenderId: '265813166121',
      appId: '1:265813166121:web:2e71076ef9ef555513f438',
    },
    settings: {},
  },
  openTok: {
    apiKey: '45695482',
  },
  sentry: {
    enabled: true,
  },
  zoom: {
    apiKey: 'SspUrKQ0TQutl1NgWiEKRQ',
    meetingBaseUrl: 'https://zoom.us/j',
  },
  launchDarklyClientId: '6050137f824e970bccf186cc',
  auth0: {
    domain: 'qual-onemedical.auth0.com',
    clientId: 'KDjRf5JXrB01V95AbkWrBncBhZYfSAbA',
    audience: 'https://onemedical.com',
    connection: 'okta-saml',
    httpInterceptor: {
      allowedList: [
        {
          uri: `${apiServer}/api/*`,
          tokenOptions: {
            audience: 'https://onemedical.com',
          },
        },
      ],
    },
  },
};
